import { $host } from '../../libs/user-api';
import io from 'socket.io-client';

export default {
  state: {
    apartmentsShow: false,
    carShow: false,
    globalTest: true,
    showAuthForm: false,
    denyReasonsAuto: [],
    denyReasonsHut: [],
    showSnowOnMobile: true,
    // socket: io(
    //   `${
    //     process.env.VUE_APP_MODE === 'production'
    //       ? 'https://app.a-prokat.com/'
    //       : process.env.NODE_ENV === 'production'
    //       ? 'https://testapp.a-prokat.com/'
    //       : 'http://localhost:8080'
    //   }`
    // ),
    // socket: io('http://localhost:8080'),
    socket: null,
  },
  mutations: {
    updateShownCar(state, bool) {
      state.carShow = bool;
    },
    updateShowSnowOnMobile(state, bool) {
      state.showSnowOnMobile = bool;
    },
    updateShownApartments(state, bool) {
      state.apartmentsShow = bool;
    },
    updateGlobalTest(state, bool) {
      state.globalTest = bool;
    },
    updateDenyReasonsAuto(state, array) {
      state.denyReasonsAuto = array;
    },
    updateShowAuthForm(state, bool) {
      if (typeof bool === 'undefined') state.showAuthForm = !state.showAuthForm;
      else state.showAuthForm = bool;
    },
    updateDenyReasonsHut(state, array) {
      state.denyReasonsHut = array;
    },
    updateSocket(state, userId) {
      state.socket = io(
        `${
          process.env.VUE_APP_MODE === 'production'
            ? 'https://app.a-prokat.com'
            : process.env.NODE_ENV === 'production'
            ? 'https://testapp.a-prokat.com'
            : 'http://localhost:8080'
        }/clients`,
        {
          query: { userId: userId },
        }
      );
    },
  },
  actions: {
    async getDenyReasons(ctx) {
      const { data } = await $host.get('/api/freecars/denyreasons');
      ctx.commit('updateDenyReasonsAuto', data.denyReasonsAuto);
      ctx.commit('updateDenyReasonsHut', data.denyReasonsHut);
    },
    async getGlobalTest(ctx) {
      const { data } = await $host.get('/api/freecars/globalTest');
      ctx.commit('updateGlobalTest', data.globalTest);
      ctx.commit('updateShowSnowOnMobile', data.showSnowOnMobile);
    },
  },
  getters: {
    carShow(state) {
      return state.carShow;
    },
    apartmentsShow(state) {
      return state.apartmentsShow;
    },
    denyReasonsAuto(state) {
      return state.denyReasonsAuto;
    },
    denyReasonsHut(state) {
      return state.denyReasonsHut;
    },
    socket(state) {
      return state.socket;
    },
    globalTest(state) {
      return state.globalTest;
    },
    showSnowOnMobile(state) {
      return state.showSnowOnMobile;
    },
    showAuthForm(state) {
      return state.showAuthForm;
    },
  },
};
