import { $host } from '../../libs/user-api';
export default {
  state: {
    cities: [],
    regions: [],
    currentCityId: null,
  },
  mutations: {
    updateCities(state, cities) {
      state.cities = cities;
    },
    updateRegions(state, regions) {
      state.regions = regions;
    },
    changeCurrentCityId(state, id) {
      state.currentCityId = id;
    },
  },
  actions: {
    async getCitites(ctx) {
      let citiesData = await $host.get(`/api/freecars/cities`);
      ctx.commit('updateCities', citiesData.data);
    },
    async getRegions(ctx) {
      if (ctx.state.regions.length) return;
      let regionsData = await $host.get(`/api/freecars/regions`);
      ctx.commit('updateRegions', regionsData.data);
    },
  },
  getters: {
    allCities(state) {
      return state.cities.filter((x) => x.id !== 696);
    },
    allRegions(state) {
      return state.regions;
    },
    currentCityId(state) {
      return state.currentCityId;
    },
    currentCityAndRegionNames(state) {
      const props = {
        name: '',
        regionName: '',
      };
      const candidate = state.cities.find((x) => x.id == state.currentCityId);
      if (candidate) {
        props.name = candidate.name;
        props.regionName = candidate.regionName;
      }
      return props;
    },
  },
};
