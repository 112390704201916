import { $host } from '../../libs/user-api';
import { filter } from 'lodash';
import moment from 'moment';

const defaultCar={
  id: null,
  model: null,
  gosNumber: null,
  carCityId: null,
  type: 'car',
  // carClass: carClass,
  // transmission: transmission,
  // fuel: fuel,
  // price: car.values[10],
  // // imgLink: _.get(car, 'values[151][0].url', ''),
  statuses: [],
  description: '',
  priceDescription: '',
  carAdress: '',
  filialId: null,
  price: null,
  tariff: null,
  carFilialWorkingHours: [],
  carDeliveryAvalible: null,
  carDeliveryNonWorkHours: [],
  carPlacesDelivery: [],
  // photos: photos
}
export default {
  state: {
    cars: [],
    car: {
      id: null,
      model: null,
      gosNumber: null,
      carCityId: null,
      type: 'car',
      // carClass: carClass,
      // transmission: transmission,
      // fuel: fuel,
      // price: car.values[10],
      // // imgLink: _.get(car, 'values[151][0].url', ''),
      statuses: [],
      description: '',
      priceDescription: '',
      carAdress: '',
      filialId: null,
      price: null,
      tariff: null,
      carFilialWorkingHours: [],
      carDeliveryAvalible: null,
      carDeliveryNonWorkHours: [],
      carPlacesDelivery: [],
      // photos: photos
    },
    filters: {
      cityId: null,
      dateAt: moment().format('YYYY-MM-DD'),
      secondDate: null,
      statuses: [],
      price: null,
      tariff: [],
      tranmissions: [],
      carClasses: [],
      seatCount: [],
      models: [],
      fuels: [],
      carProductYear: null,
      carAdres: [],
      visible: false,
    },
  },
  mutations: {
    updateCars(state, cars) {
      state.cars = cars;
    },
    clearFilter(state, exceptionObj = { filialStay: false }) {
      state.filters.statuses = [];
      state.filters.price = null;
      state.filters.tranmissions = [];
      state.filters.tariff = [];
      state.filters.carClasses = [];
      state.filters.seatCount = [];
      state.filters.models = [];
      state.filters.fuels = [];
      if (!exceptionObj.filialStay) state.filters.carAdres = [];
      state.filters.carProductYear = null;
    },
    updateCar(state, car = defaultCar) {
      state.car = car;
    },
    // clearCars(state) {
    //     state.cars = []
    // }
    updateCarFilialWorkingHours(state, value) {
      state.carFilialWorkingHours = value;
    },
    updateCarDeliveryAvalible(state, value) {
      state.carDeliveryAvalible = value;
    },
    updateCarDeliveryNonWorkHours(state, value) {
      state.carDeliveryNonWorkHours = value;
    },
    updateCarPlacesDelivery(state, value) {
      state.carPlacesDelivery = value;
    },
    adaptCarVideo(state, width, height) {
      if (state.car.photos[2].video) {
        state.car.photos[2].video = state.car.photos[2].video.replace(
          'width="100" height="100"',
          `width="${width}" height="${height}"`
        );
      }
    },
  },
  actions: {
    // async getCars(ctx) {
    //    let cars = await $host.get(`http://192.168.1.54/freecars/cars`);
    // //    console.log(cars.data);
    //    ctx.commit('updateCars', cars.data )
    // },
    async getCarsByCityId(ctx, { cityId, dateAt, secondDate, haveFilials }) {
      console.log('have filials ', haveFilials);
      let cars = await $host.get(`/api/freecars/cars`, {
        params: {
          cityId: cityId,
          date: dateAt,
          secondDate: secondDate,
          haveFilials: haveFilials
        },
      });
      ctx.commit('updateCars', cars.data);
    },
    async getCarById(ctx, carId) {
      const isNan = Number.isNaN(parseInt(carId));
      if (!isNan) {
        try {
          let car = await $host.get(`/api/freecars/car`, {
            params: {
              carId: carId,
            },
          });
          ctx.commit('updateCar', car.data);
          return car.statusText;
        } catch (e) {
          console.log('error!', e);
          return null;
        }
      } else return null;
    },
  },
  getters: {
    allCars: (state) => {
      let filteredCars = state.cars;
      if (state.filters.city)
        filteredCars = filter(filteredCars, (o) => {
          return o.cityLink[0].recordId == state.filters.city;
        });
      if (state.filters.statuses.length)
        filteredCars = filter(filteredCars, (car) => {
          return state.filters.statuses.includes(car.status.id);
        });
      if (state.filters.price)
        filteredCars = filter(filteredCars, (o) => {
          return o.price <= state.filters.price;
        });
      if (state.filters.tranmissions.length)
        filteredCars = filter(filteredCars, (o) => {
          return state.filters.tranmissions.includes(o.transmission);
        });
      if (state.filters.models.length)
        filteredCars = filter(filteredCars, (o) => {
          return state.filters.models.includes(o.model);
        });
      if (state.filters.fuels.length)
        filteredCars = filter(filteredCars, (o) => {
          return state.filters.fuels.includes(o.fuel);
        });
      if (state.filters.carClasses.length)
        filteredCars = filter(filteredCars, (o) => {
          return state.filters.carClasses.includes(o.carClass);
        });
      if (state.filters.seatCount.length) {
        filteredCars = filter(filteredCars, (o) => {
          return Math.min(...state.filters.seatCount) <= o.seatCount;
        });
      }
      if (state.filters.carAdres.length)
        filteredCars = filter(filteredCars, (o) => {
          return state.filters.carAdres.includes(o.carAdres.recordTitle);
        });
      filteredCars.sort((a, b) => {
        if (a.status.id === b.status.id) {
          return b.price - a.price;
        }
        return a.status.id > b.status.id ? 1 : -1;
      });
      return filteredCars;
      // return _.sortBy(
      //   _.sortBy(filteredCars, (o) => {
      //     if ([2, 3].includes(+o.status.id))
      //       return moment(o.status.dateAt).format('x');
      //     return toString(o.price).replace(/\D+/g, '') * -1;
      //   }),
      //   (o) => {
      //     return o.status.id;
      //   }
      // );
    },
    car: (state) => {
      return state.car;
    },
    filters: (state) => {
      return state.filters;
    },
    statuses: (state) => {
      return state.cars
        .map((i) => i.status)
        .filter((value, index, self) => self.indexOf(value) === index);
    },
    carClasses: (state) => {
      return state.cars
        .map((i) => i.carClass)
        .filter((value, index, self) => self.indexOf(value) === index);
    },
    tranmissions: (state) => {
      return state.cars
        .map((i) => i.transmission)
        .filter((value, index, self) => self.indexOf(value) === index);
    },
    models: (state) => {
      return state.cars
        .map((i) => i.model)
        .filter((value, index, self) => self.indexOf(value) === index);
    },
    fuels: (state) => {
      return state.cars
        .map((i) => i.fuel)
        .filter((value, index, self) => self.indexOf(value) === index);
    },
    carAdres: (state) => {
      return state.cars
        .map((i) => i.carAdres.recordTitle)
        .filter((value, index, self) => self.indexOf(value) === index);
    },
    seatCount: (state) => {
      return state.cars
        .map((i) => i.seatCount)
        .filter((value, index, self) => self.indexOf(value) === index);
    },
  },
};
