import { $host } from '../../libs/user-api';
import router from '../../router/index';

const getDefaultState = () => {
  return {
    id: '',
    title: '',
    roles: [],
    balance: 0,
    phone: '',
    emails: '',
    birthday: '',
    reinvestPercent: 0,
    reinvestFlag: false,
    moneyBox: 0,
    requisites: '',
    passport: '',
    passportDate: '',
    errorMessage: '',
    registrationRegion: '',
    coworker: null,
    // по сути должен быть массив с реферальными вещами по этому в объект не пихал
    referDocumentLink: null,
    referalLink: null,
    refLinkWa: null,
    refLinkTg: null,
    refLinkVk: null,
    referalSelfBookingId: null,
    // по сути должен быть массив с реферальными вещами по этому в объект не пихал
    // actualAdress: "",
    files: {
      passportFront: {},
      passportBack: {},
      driveLicenseFront: {},
      driveLicenseBack: {},
    },
    auth: false,
    lastBooking: null,
    agreed: false,
    whatsappIsDefaultChannel: false,
  };
};
const state = getDefaultState();

export default {
  state,
  mutations: {
    updateFile(state, { field, link, title }) {
      state.files[field].link = link;
      state.files[field].title = title;
    },
    passportFrontChanged(state, file) {
      state.files.passportFront = file;
    },
    passportBackhanged(state, file) {
      state.files.passportBack = file;
    },
    driveLicenseFrontChanged(state, file) {
      state.files.driveLicenseFront = file;
    },
    driveLicenseBackhanged(state, file) {
      state.files.driveLicenseBack = file;
    },
    updateStatus(state, status) {
      state.status = status;
    },
    updateAuth(state, auth) {
      state.auth = auth;
    },
    updateErrorMessage(state, message) {
      state.errorMessage = message;
    },
    updatePhone(state, phone) {
      state.phone = phone;
    },
    updateReferalLink(state, link) {
      state.referalLink = link;
    },
    updateReferDocumentLink(state, link) {
      state.referDocumentLink = link;
    },
    updateRefLinkWa(state, link) {
      state.refLinkWa = link;
    },
    updateRefLinkTg(state, link) {
      state.refLinkTg = link;
    },
    updateRefLinkVk(state, link) {
      state.refLinkVk = link;
    },
    updateReferalSelfBookingId(state, id) {
      state.referalSelfBookingId = id;
    },
    updateUser(
      state,
      {
        // actualAdress,
        balance,
        birthday,
        emails,
        id,
        moneyBox,
        phone,
        reinvestFlag,
        reinvestPercent,
        roles,
        title,
        requisites,
        passport,
        passportDate,
        //  passportIssued,
        registrationRegion,
        whatsappIsDefaultChannel,
        coworker,
      }
    ) {
      // state.actualAdress = actualAdress;
      state.balance = balance;
      state.birthday = birthday;
      state.emails = emails;
      state.id = id;
      state.moneyBox = moneyBox;
      (state.phone = phone), // .replace(/[+\(\)-]/g,'');
        (state.reinvestFlag = reinvestFlag);
      state.reinvestPercent = reinvestPercent;
      state.roles = roles;
      state.title = title;
      state.requisites = requisites;
      state.passport = passport;
      state.passportDate = passportDate;
      state.coworker = coworker;
      // state.passportIssued = passportIssued;
      state.registrationRegion = registrationRegion;
      state.whatsappIsDefaultChannel = whatsappIsDefaultChannel;
    },
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
    updateLastBooking(state, booking) {
      state.lastBooking = booking;
    },
    updateAgreement(state, value) {
      state.agreed = value;
    },
    //   ...mapMutations([
    //     'updateBookingState'
    // ])
  },
  actions: {
    async createAppeal(ctx, text) {
      try {
        if (!this.state.user.auth) throw new Error('auth is need');
        const { data } = await $host.post('/api/auth/appeal', {
          userId: this.state.user.id,
          message: text,
        });
        if (data) return data;
      } catch (e) {
        return {
          message: 'Произошла ошибка, попробуйте позднее',
          typeStyle: 'error',
        };
      }
    },

    async saveFiles(ctx) {
      async function uploadFilesToBP(file2, field, userId) {
        async function uploadFiles2(file) {
          const reader = new FileReader();

          await new Promise((resolve) => {
            reader.onload = resolve;
            reader.readAsDataURL(file);
          });

          const img = document.createElement('img');

          await new Promise((resolve) => {
            img.onload = resolve;
            img.src = reader.result;
          });

          /*if (img.width > 2160 && img.height > 3840) {
            let newWidth = img.width;
            let newHeight = img.height;
            if (img.width > 2160) {
              newWidth = 2160;
              newHeight = (img.height * 2160) / img.width;
            }
            if (newHeight > 3840) {
              newHeight = 3840;
              newWidth = (img.width * 3840) / img.height;
            }

            const canvas = document.createElement('canvas');
            canvas.width = newWidth;
            canvas.height = newHeight;

            const ctx = canvas.getContext('2d');

            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            const resizedFile = await new Promise((resolve) => {
              canvas.toBlob(resolve, file.type);
            });

            return resizedFile;
          }*/

          return file;
        }

        const file = await uploadFiles2(file2);
        let fileKeysData = await $host.post('/api/freecars/getFileKeys', {
          name: file.name || field + `.${file.type.split('/')[1]}` || 'rnd.jpg',
          type: file.type,
          size: file.size,
          userId: userId,
          field: field,
        });

        let fileKeys = fileKeysData.data;
        const formData = new FormData();
        formData.append('key', fileKeys.fileKey);
        formData.append('acl', 'private');
        formData.append('AWSAccessKeyId', fileKeys.AWSAccessKeyId);
        formData.append('Policy', fileKeys.police);
        formData.append('Signature', fileKeys.signature);
        formData.append('Content-Type', fileKeys.mimeType);
        formData.append('file', file);
        try {
          await $host.post(fileKeys.uploadUrl, formData);
        } catch (e) {
          console.log(e);
        }

        let link = `${fileKeys.uploadUrl}/${fileKeys.fileKey}`;
        let title = fileKeys.name;
        const obj = { field, link, title };
        return obj;
      }

      const tempStoreOfFileSizes = [];
      let flag = true;
      const iterablePromiseArray = [];
      for (const field in this.state.user.files) {
        let file = this.state.user.files[field];
        const typeOfFile = file.type ? file.type : 'image';
        const sizeOfFile = file.size ? file.size : 1;
        if (typeOfFile.indexOf('image') !== 0) {
          file = null;
        }
        if (sizeOfFile > 50 * 1024 * 1024) {
          file = null;
        }

        if (tempStoreOfFileSizes.indexOf(sizeOfFile) == -1 && file.name) {
          tempStoreOfFileSizes.push(sizeOfFile);
          iterablePromiseArray.push(
            uploadFilesToBP(file, field, this.state.user.id)
          );
        } else if (!file.link) {
          this.state.user.files[field] = null;
          flag = false;
        }
      }
      const results = await Promise.allSettled(iterablePromiseArray);
      results.forEach((result) => {
        if (result.reason) flag = false;
        else {
          const { field, link, title } = result.value;
          ctx.commit('updateFile', { field, link, title });
        }
      });

      return flag;
    },
    // async updateUserFromBooking(ctx) {
    //   try {
    //     ctx.commit("updateUser", ctx.state);
    //   } catch (e) {
    //     console.log(e);
    //   }
    // },
    async restorePassword(ctx, phone) {
      try {
        let { data } = await $host.post('/api/auth/restore_password', { phone });
        return data;
      } catch (e) {
        console.log(e);
      }
    },
    async getUser(ctx) {
      // console.log('updating user');
      const { data } = await $host.get('/api/auth/login');
      ctx.commit('updateAuth', data.status);
      if (data.status) {
        // localStorage.isAuthAprokat = true;
        // console.log('this is user', data.user)
        ctx.commit('updateUser', data.user);
        ctx.commit('updateSocket', data.user.id);
        //update files
        for (let filename in data.user.files) {
          ctx.commit('updateFile', {
            field: filename,
            link: data.user.files[filename].url,
            title: data.user.files[filename].title,
          });
        }
      }
      return data;
    },
    async setNewPassword(ctx, password) {
      try {
        let setPass = await $host.post('/api/auth/set_password', { password });
        return setPass;
      } catch (e) {
        console.log(e);
      }
    },
    async logoutUser(ctx) {
      try {
        await $host.post('/api/auth/logout');
        ctx.commit('resetState');
        // router.push("/login");
      } catch (e) {
        console.log(e);
      }
    },
    async loginUser(ctx, obj) {
      try {
        ctx.commit('updateErrorMessage', '');
        const { phone, password } = obj;
        let logging = await $host
          .post('/api/auth/login', { phone, password })
          .catch(() => {
            ctx.commit('updateErrorMessage', 'Неверный логин и/или пароль');
            // console.log('!Error!', e);
            return false;
          });
        if (!logging) {
          ctx.commit('updateErrorMessage', 'Неверный логин и/или пароль');
          return false;
        }
        if (logging?.data.status) {
          const auth = logging.data.status;

          ctx.commit('updateUser', logging.data.user);
          // localStorage.isAuthAprokat = true;
          ctx.commit('updateAuth', auth);
          router.push('/');
          return logging.data.status;
        } else {
          console.log('try again');
          ctx.commit('updateErrorMessage', 'Неверный логин и/или пароль');
          return false;
        }
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async getReferalsBooking(ctx) {
      const { data } = await $host.get(`/api/user_referals`, {
        params: {
          userId: this.state.user.id,
        },
      });
      if (data.referDocumentLink)
        ctx.commit('updateReferDocumentLink', data.referDocumentLink);
      if (data.referalLink) ctx.commit('updateReferalLink', data.referalLink);
      if (data.refLinkVk) ctx.commit('updateRefLinkVk', data.refLinkVk);
      if (data.refLinkTg) ctx.commit('updateRefLinkTg', data.refLinkTg);
      if (data.refLinkWa) ctx.commit('updateRefLinkWa', data.refLinkWa);
      if (data.referalSelfBookingId)
        ctx.commit('updateReferalSelfBookingId', data.referalSelfBookingId);
      // if (!data.founded) return [];
      return data;
    },
    // async LoginFromBooking(ctx) {
    //   console.log('this is ctx from userstore', ctx)
    //   const {phone} = ctx.state
    //   const {id} = ctx.getters.car
    //   let logging = await $host.post('/auth/login/booking', {phone, carId: id})
    //   console.log(logging)

    // },
  },

  getters: {
    user(state) {
      return state;
    },
    userAuth(state) {
      return state.auth;
    },
    userFiles(state) {
      return state.files;
    },
  },
};
